/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { axiosInstanceWithAccessToken } from '../../shared/util';
import { OrderScanningModeEnum } from '../_type';
import { IAppContextState, useAppContext } from '../context';

let barcode = '';
let clearBarcodeInterval: NodeJS.Timeout;
let clearScannerStateTimeout: NodeJS.Timeout;
// let autoStopTimeout: NodeJS.Timeout;

export const useHandleToggleScanning = (): {
    start: VoidFunction;
    stop: VoidFunction;
} => {
    const appCtx = useAppContext();
    const appCtxStateRef = React.useRef<IAppContextState>(appCtx.state);

    const handleSubmitBarcode = (code: string): void => {
        if (appCtxStateRef.current.scanner.isProcessing) return;

        appCtx.setState((current) => {
            const _cloned = { ...current };
            _cloned.scanner.isProcessing = true;
            _cloned.scanner.text = code;
            return _cloned;
        });

        const endpoint =
            appCtxStateRef.current.scanner.mode === OrderScanningModeEnum.Ship
                ? '/api/v1/pack-order/goods-issue'
                : '/api/v1/pack-order';
        axiosInstanceWithAccessToken
            .post(endpoint, { po_number: code })
            .then(() => {
                appCtx.setState((current) => {
                    const _cloned = { ...current };
                    _cloned.scanner.isProcessed = true;
                    _cloned.orders.timestamp = +new Date();
                    return _cloned;
                });
            })
            .catch(() => {
                appCtx.setState((current) => {
                    const _cloned = { ...current };
                    _cloned.scanner.isProcessed = true;
                    return _cloned;
                });
            })
            .finally(() => {
                clearScannerStateTimeout = setTimeout(() => {
                    appCtx.setState((current) => {
                        const _cloned = { ...current };
                        _cloned.scanner.isProcessing = false;
                        _cloned.scanner.isProcessed = false;
                        _cloned.scanner.text = '';
                        return _cloned;
                    });
                }, 1000);
            });
    };

    const handleOnScannerDeviceCapture = React.useCallback((e: KeyboardEvent): void => {
        e.preventDefault();

        if (clearBarcodeInterval) clearInterval(clearBarcodeInterval);

        if (e.key === 'Enter') {
            if (barcode) {
                console.log(barcode);
                // clearTimeout(autoStopTimeout);
                // autoStopTimeout = setTimeout(stop, 1000 * 60);
                handleSubmitBarcode(barcode);
            }
            barcode = '';
            return;
        }

        if (e.key !== 'Shift') barcode += e.key;

        clearBarcodeInterval = setInterval(() => (barcode = ''), 200);
    }, []);

    const start = (): void => {
        window.addEventListener('keydown', handleOnScannerDeviceCapture);
        // autoStopTimeout = setTimeout(stop, 1000 * 60);
        appCtx.setState((current) => {
            const _cloned = { ...current };
            _cloned.scanner.isPlaying = true;
            return _cloned;
        });
    };

    const stop = (): void => {
        window.removeEventListener('keydown', handleOnScannerDeviceCapture);
        clearInterval(clearBarcodeInterval);
        clearTimeout(clearScannerStateTimeout);
        // clearTimeout(autoStopTimeout);
        appCtx.setState((current) => {
            const _cloned = { ...current };
            _cloned.scanner.isPlaying = false;
            return _cloned;
        });
    };

    React.useEffect(() => {
        appCtxStateRef.current = appCtx.state;
    }, [appCtx.state]);

    React.useEffect(() => {
        return stop;
    }, []);

    return { start, stop };
};
