import styled from 'styled-components';

import { MainContentOrderListFilterAttribute } from './attribute';
import { MainContentOrderListSearch } from './search';

const StyledMainContentOrderListFilter = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    gap: 60px;
`;

export const MainContentOrderListFilter = (): JSX.Element => {
    return (
        <StyledMainContentOrderListFilter>
            <MainContentOrderListSearch />
            <MainContentOrderListFilterAttribute />
        </StyledMainContentOrderListFilter>
    );
};
