import { token } from '../../token';
import { IIconProps } from '../type';

export const PlayIconFilled = ({
    width = 20,
    height = 20,
    color = token.get<string>('global.color.grey-2'),
}: IIconProps): JSX.Element => (
    <svg
        width={height}
        height={width}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.1378 10.5689L9.60498 7.30249C8.40816 6.70408 7 7.57437 7 8.91246V15.0875C7 16.4256 8.40816 17.2959 9.60498 16.6975L16.1378 13.4311C17.3171 12.8414 17.3171 11.1586 16.1378 10.5689Z"
            fill={color}
        />
    </svg>
);
